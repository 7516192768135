import { APIFilter } from '@/utils/api'

export default {
  async selectDetail (Vue, filter, search, sorter, page, idparteTrabajo, idsubsis) {
    let apiCalls = []
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idparte_trabajo', idparteTrabajo)
      .addExact('idsubsis', idsubsis)
      .addGT('estado', 0)
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.materialSistema.searchFilter(search))
    }
    apiCalls.push({
      name: 'selectListParteTrabajoMatsist',
      method: 'parteTrabajoMatsist.selectList',
      params: { page, filter: apiFilter, sorter },
    })
    const apiFilterIdparte = new APIFilter()
      .addExact('idparte_trabajo', idparteTrabajo)
      .addGT('estado', 0)
    apiCalls.push({
      name: 'selectAccionesPorSubsis',
      method: 'parteTrabajoMatsist.selectAccionesPorSubsis',
      params: { filter: apiFilterIdparte },
    })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectListParteTrabajoMatsistRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idparte_trabajo_matsist', pks)
    const resp = await Vue.$api.call('parteTrabajoMatsist.selectList', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
